<script>
import { hasRouteDay } from "@/mixins/routerParams";
import { hasCurrentStoreAccess } from "@/mixins/store";

import { format, endOfYesterday } from "date-fns";
import { DAY_CLOSE_REPORT_QUERY } from "./graphql";

export default {
  name: "DayCloseReportView",
  mixins: [hasRouteDay, hasCurrentStoreAccess],
  props: {
    businessDate: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    reportLoading: false,
    reportError: false,
  }),
  apollo: {
    dayCloseReport: {
      query: DAY_CLOSE_REPORT_QUERY,
      variables() {
        return {
          storeId: this.storeId,
          businessDate: this.bizDate,
        };
      },
      watchLoading(isLoading) {
        this.reportLoading = isLoading;
      },
      skip() {
        return !this.storeId;
      },
      update(data) {
        this.reportError = false;
        const { dayCloseReport } = data;
        if (dayCloseReport === null) {
          this.reportError = true;
          URL.revokeObjectURL(this.downloadUrl);
        }
        return dayCloseReport;
      },
    },
  },
  computed: {
    downloadUrl() {
      const blob = new Blob([this.dayCloseReport], {
        type: "text/plain",
      });
      return URL.createObjectURL(blob);
    },
    reportReady() {
      return !this.reportLoading && !this.reportError;
    },
    filename() {
      return `${this.storeNumber}-S${format(this.dayDate, "MMddyy")}.txt`;
    },
    storeNumber() {
      return this.currentStore?.storeNumber;
    },
    emailLink() {
      return `mailto:${this.$t("global.supportEmail")}`;
    },
    bizDate() {
      return this.businessDate ? Date.parse(this.businessDate) : this.dayDate;
    },
  },
  created() {
    const yesterday = endOfYesterday();
    if (!this.$route.query.d) this.dayDate = yesterday;
  },
  beforeDestroy() {
    URL.revokeObjectURL(this.downloadUrl);
  },
  methods: {
    resetReport() {
      this.reportError = false;
      URL.revokeObjectURL(this.downloadUrl);
    },
  },
};
</script>

<template>
  <v-container fluid>
    <v-row justify="center" class="py-4">
      <v-progress-circular v-if="reportLoading" indeterminate />
      <v-card v-else :flat="!reportReady">
        <v-card-text>
          <p v-if="reportError" class="text-body-2">
            {{ $t("report.app.dayClose.notFoundText") }}
            <a :href="emailLink">{{ $t("global.supportEmail") }}</a>
          </p>
          <pre
            v-else
            id="content"
            class="text--primary"
            style="font-size: 11px"
            >{{ dayCloseReport }}</pre
          >
        </v-card-text>
      </v-card>
    </v-row>
    <v-btn
      v-if="reportReady"
      fab
      fixed
      bottom
      right
      color="primary"
      :href="downloadUrl"
      :download="filename"
    >
      <v-icon>$download</v-icon>
    </v-btn>
  </v-container>
</template>

<style></style>
